<template>
  <div class="container">
    <div class="container-title">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        style="cursor: pointer"
        @click="handleRouterBack"
      >
      <div class="container-title-t">
        Detail Tiket
      </div>
    </div>
    <div
      class="rounded-xl ring-gray-300 mt-8 p-2 container-border"
      style="border: 1px solid #E2E2E2"
    >
      <div
        v-b-toggle.collapse-1
        class="flex mb-2"
      >
        <div class="flex justify-between w-full">
          <strong
            class="font-medium text-lg"
            style="color: #000000 !important;"
          >Detail Tiket</strong>
        </div>
        <span class="when-opened">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-up-2.svg"
            alt="Komerce"
            style="margin-left: 5px"
          >
        </span>
        <span class="when-closed">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
            alt="Komerce"
            style="margin-left: 5px"
          >
        </span>
      </div>
      <BCollapse
        id="collapse-1"
        :visible="true"
      >
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Status Tiket
            </p>
            <div
              class="status"
              v-bind="
                dataDetailTiket.status_ticket === 'Batal'
                  ? { class: 'cancel' }
                  : dataDetailTiket.status_ticket === 'Selesai'
                    ? { class: 'finish' }
                    : dataDetailTiket.status_ticket === 'Belum diproses'
                      ? { class: 'onProsess' }
                      : { class: 'needProsess' }
              "
            >
              {{ dataDetailTiket.status_ticket }}
            </div>
          </b-col>
          <b-col md="6">
            <p class="font-bold">
              Status Order
            </p>
            <div class="flex items-center gap-[8px]">
              <b-badge
                v-if="dataDetailTiket.order_status !== 'Rusak'"
                class="h-fit"
                :variant="orderStatusVariant(dataDetailTiket.order_status)"
                :style="
                  dataDetailTiket.order_status === 'Hilang'
                    ? 'background-color: #FCBEBE!important; color: #E31A1A!important'
                    : ''
                "
              >
                {{
                  dataDetailTiket.order_status === 'Hilang'
                    ? 'Paket Hilang'
                    : dataDetailTiket.order_status
                }}
              </b-badge>
              <b-badge
                v-if="dataDetailTiket.order_status === 'Rusak'"
                class="h-fit"
                :variant="orderStatusVariant(dataDetailTiket.order_status)"
                :style="
                  dataDetailTiket.order_status === 'Rusak'
                    ? 'background-color: #FCBEBE!important; color: #E31A1A!important'
                    : ''
                "
              >
                {{
                  dataDetailTiket.order_status === 'Rusak'
                    ? 'Paket Rusak'
                    : dataDetailTiket.order_status
                }}
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Nomor Tiket
            </p>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="dataDetailTiket.ticket_no"
                disabled
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CopyIcon"
                  class="cursor-pointer"
                  size="20"
                  @click="copyTicket(dataDetailTiket.ticket_no)"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="6">
            <p class="font-bold">
              Nomor Resi
            </p>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="dataDetailTiket.awb"
                disabled
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CopyIcon"
                  class="cursor-pointer"
                  size="20"
                  @click="copyTicket(dataDetailTiket.awb)"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Seller
            </p>
            <b-form-input
              disabled
              :value="dataDetailTiket.seller"
            />
          </b-col>
          <b-col md="6">
            <p class="font-bold">
              Buyer
            </p>
            <b-form-input
              disabled
              :value="dataDetailTiket.customer"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col md="6">
            <p class="font-bold">
              Jenis Tiket
            </p>
            <b-form-input
              disabled
              :value="dataDetailTiket.type_ticket"
            />
            <p class="font-bold mt-1">
              Deskripsi
            </p>
            <b-form-textarea
              disabled
              :value="dataDetailTiket.description"
            />
          </b-col>
          <b-col
            v-if="dataDetailTiket.attachments !== null"
            md="6"
          >
            <p class="font-bold">
              File
            </p>
            <a
              v-for="(item, index) in dataDetailTiket.attachments"
              :key="index"
              target="_blank"
              :href="getUrlPath(item)"
              class="download"
            >
              {{ getValueFile(item) }}
            </a>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            md="12"
          >
            <b-button
              variant="outline-primary"
              class="text-red-400 absolute bottom-0 right-8"
              @click="handleDetailOrder()"
            >
              Detail Order
            </b-button>
          </b-col>
        </b-row>
      </BCollapse>
    </div>

    <HistoryTiket
      v-if="dataDetailTiket"
      :data="dataDetailTiket"
      :handleregeximage="handleRegexImage"
      :handleregexdoc="handleRegexDoc"
      :handelregexvideo="handelRegexVideo"
      :formatdate="formatDate"
      :handleextension="handleExtension"
      :handlecuturl="handleCutUrl"
      @executeParentMethod="initializeDetail()"
    />
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import moment from 'moment'
import HistoryTiket from './history-chat-ticket-collaborator.vue'

export default {
  name: 'DetailTiket',
  components: {
    HistoryTiket,
    BCollapse,
  },
  data() {
    return {
      dataDetailTiket: null,
      statusPriority: 0,
      loading: false,
    }
  },
  async created() {
    await this.initializeDetail()
  },
  methods: {
    initializeDetail() {
      this.$http_collaborator.get(`/ticket/api/v1/ticket/admin/list/${this.$route.params.id}`).then(res => {
        this.dataDetailTiket = res.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.$toast_error({ message: err })
      })
    },
    async copyTicket(data) {
      try {
        await navigator.clipboard.writeText(data)
        const Toast = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          didOpen: toast => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          },
        })

        Toast.fire({
          icon: 'success',
          title: '<span class="text-success">Success copy to clipboard</span>',
          showCloseButton: true,
        })
        // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    handleDetailOrder() {
      this.$router.push({
        path: `/tiket/kolaborator/detail/order/${this.dataDetailTiket.order_id}`,
      })
    },
    handleRouterBack() {
      this.$router.back()
    },
    getValueFile(v) {
      if (v && v.includes('/attachment/dev/')) {
        return v.replace('/attachment/dev/', '')
      }
      return v
    },
    getUrlPath(v) {
      const baseUrl = 'https://storage.googleapis.com/komerce-openapi-bucket'
      return baseUrl + v
    },
    handleCutUrl(value) {
      return value.substring(value.lastIndexOf('/') + 1, value.length)
    },
    handleRegexImage(filename) {
      return /\.(png|svg|jpg|jpeg|gif|webp|jfif)$/i.test(filename)
    },
    handleRegexDoc(filename) {
      return /\.(xlsx|xls|doc|docx|pdf)$/i.test(filename)
    },
    handelRegexVideo(filename) {
      return /\.(mp4)$/i.test(filename)
    },
    formatDate(value) {
      return moment(value)
        .format('D MMM YYYY | HH:mm')
    },
    handleExtension(value) {
      return value.split('.')
        .pop()
    },
    orderStatusVariant(data) {
      let result = ''
      if (data === 'Diajukan') {
        result = 'light-primary'
      } else if (data === 'Dipacking') {
        result = 'light-info'
      } else if (data === 'Dikirim') {
        result = 'light-warning'
      } else if (data === 'Diterima') {
        result = 'light-success'
      } else if (data === 'Retur') {
        result = 'light-danger'
      } else if (data === 'Batal') {
        result = 'light-dark'
      }
      return result
    },
  },
}
</script>
  <style scoped lang="scss" src="../DetailTiket.scss" />
