import { render, staticRenderFns } from "./history-chat-ticket-collaborator.vue?vue&type=template&id=63d44696&scoped=true&"
import script from "./history-chat-ticket-collaborator.vue?vue&type=script&lang=js&"
export * from "./history-chat-ticket-collaborator.vue?vue&type=script&lang=js&"
import style0 from "../HistoryTiket.scss?vue&type=style&index=0&id=63d44696&lang=true&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d44696",
  null
  
)

export default component.exports